import React, { Component } from "react"

export default class Header extends Component{

    render(){

        return (
<div><nav className="main-header navbar navbar-expand navbar-white navbar-light">
    {/* Left navbar links */}
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" data-widget="pushmenu" href="#"><i className="fas fa-bars" /></a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/kullaniciekle" className="nav-link">Kullanıcı Ekle</a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/onayli" className="nav-link">Onaylı</a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/onaybekleyen" className="nav-link">Onay Bekleyen</a>
      </li>
      <li className="nav-item d-none d-sm-inline-block">
        <a href="/kapali" className="nav-link">Kapalı</a>
      </li>
    </ul>
    {/* SEARCH FORM */}
    <form className="form-inline ml-3">
      <div className="input-group input-group-sm">
        <input className="form-control form-control-navbar" type="search" placeholder="Ara" aria-label="Search" />
        <div className="input-group-append">
          <button className="btn btn-navbar" type="submit">
            <i className="fas fa-search" />
          </button>
        </div>
      </div>
    </form>
    {/* Right navbar links */}
    <ul className="navbar-nav ml-auto">
      {/* Messages Dropdown Menu */}
      <li className="nav-item">
        <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
          <i className="fas fa-th-large" />
        </a>
      </li>
    </ul>
  </nav></div>
        )
    }
}