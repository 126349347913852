import React from 'react'

function Oletisim() {
    return(
        <div className="container">
        <div className="row">
            <div className="col-md-12">
                <div class="alert alert-primary" role="alert">
                   İletişim sayfasına hoşgeldiniz..
                </div>
            </div>
        </div>
      </div>
    )
}

export default Oletisim