import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect, useState } from 'react';
import axios from 'axios';
import {useForm} from 'react-hook-form';
import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

function KullaniciEkle() {
    let history = useHistory();

    useEffect(() => {
        const scriptTag = document.createElement('script');

        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);
    
    let {path,url} = useRouteMatch();

    const [firmaadi,setFirmaAdi] = useState("");
    const [kullaniciadi,setKullaniciAdi] = useState("");
    const [parola,setParola] = useState("");
    const [telefon,setTelefon] = useState("");
    const [email,setEmail] = useState("");
    const [lisanssuresi,setLisansSuresi] = useState("");
    const [profilresmi,setProfilResmi] = useState("");
    const [sonuc,setSonuc] = useState("");
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");
    const [image,setImage] = useState("");
    const [loading,setLoading] = useState(false);

    const [data,setData] = useState(false);


    const handleFirmaAdi = (e) => { setFirmaAdi(e.target.value); }
    const handleKullaniciAdi = (e) => { setKullaniciAdi(e.target.value); }
    const handleParola = (e) =>{ setParola(e.target.value); }
    const handleTelefon = (e) =>{ setTelefon(e.target.value); }
    const handleEmail = (e) =>{ setEmail(e.target.value); }
    const handleLisansSuresi = (e) =>{ setLisansSuresi(e.target.value); }
    const handleProfilResmi = (e) =>{ setProfilResmi(e.target.value); }


    const obj = {
        firmaadi: firmaadi,
        kullaniciadi: kullaniciadi,
        parola: parola,
        telefon: telefon,
        email: email,
        lisanssuresi: lisanssuresi,
        profilresmi: profilresmi
    };

    const objprofilimage = {
        profilresmi: profilresmi
    };

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const add = (e)=>{  
        e.preventDefault();
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnkullaniciekle.php",obj,config).then(response=>{
            if("eklendi" == response.data)
            {
                setSonuc("Kullanıcı eklendi. Onay Veriniz..!");
            }
        }).catch(error=>{
            setSonuc("Kullanıcı eklenemiyor. Veritabanı hatası");
        });
    }

    const onChange = (e) =>{
        const file = e.target.files[0];
    };
    const uploadprofileimage = () =>{
    
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/profile_image/",objprofilimage,config).then(response=>{
            if("eklendi" == response.data)
            {
                setSonuc("Kullanıcı eklendi. Onay Veriniz..!");
            }
        }).catch(error=>{
            setSonuc("Kullanıcı eklenemiyor. Veritabanı hatası");
        });
    }
    const getUsersLogged= () =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
          if("1" == response.data[0].logged)
          {
            setLogged("1");
          }
          else
          {
            setLogged("0");
          }
          }).catch(error =>{
            console.log("hata oluştu");
          });
    }


    useEffect(() => {
        getUsersLogged();

        const scriptTag = document.createElement('script');

        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    if("0" == logged)
    {
      history.push('/');
    }

    return(

        <>
        <Header/>
        <Menu/>
        <Aside/>


<div className="content-wrapper p-3">
<section class="content">
      <div class="container-fluid">
<div class="card card-default">   {/* Content Wrapper. Contains page content */}
  <div class="card-header">
        <h3 class="card-title">Kullanıcı Ekle</h3>
        
        <div class="card-tools">
            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
            <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
        </div>
    </div>
            {/* /.card */}
            <div class="card-body">
 <form onSubmit={add}>
 <label>Firma Adı:</label>
  <div className="input-group">
    <div className="input-group-append">
      <div className="input-group-text">
        <span className="fas fa-user" />
      </div>
    </div>
    <input type="text" className="form-control" placeholder="Firma Adı" onChange={handleFirmaAdi} />
  </div>
  <label>Kullanıcı Adı:</label>
  <div className="input-group">
    <div className="input-group-append">
      <div className="input-group-text">
        <span className="fas fa-user" />
      </div>
    </div>
    <input type="text" className="form-control" placeholder="Kullanıcı Adı" onChange={handleKullaniciAdi}  />
  </div>
  <label>Parola:</label>
  <div className="input-group">
    <div className="input-group-append">
      <div className="input-group-text">
        <span className="fas fa-lock" />
      </div>
    </div>
    <input type="text" className="form-control" placeholder="Parola" onChange={handleParola} />
  </div>
  <label>Telefon:</label>
  <div className="input-group">
    <div className="input-group-prepend">
      <span className="input-group-text"><i className="fas fa-phone" /></span>
    </div>
    <input type="text" name="TELEFON" className="form-control" onChange={handleTelefon} data-inputmask="&quot;mask&quot;: &quot;(999) 999-9999&quot;" data-mask />
  </div>
  {/* /.input group */}
  <label>E-mail:</label>
  <div className="input-group">
    <div className="input-group-append">
      <div className="input-group-text">
        <span className="fas fa-envelope" />
      </div>
    </div>
    <input type="email" className="form-control" placeholder="E-mail" onChange={handleEmail} />
  </div>
  <label>Lisans Süresi:</label>
  {/* Date */}
  <div className="input-group date" id="reservationdate" data-target-input="nearest">
    <input type="text" className="form-control datetimepicker-input" data-target="#reservationdate" lang="tr" placeholder="Lisans Süresi" onChange={handleLisansSuresi} />
    <div className="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
      <div className="input-group-text"><i className="fa fa-calendar" /></div>
    </div>
  </div>
  <div>
    <button className="btn btn-warning" style={{marginTop: 20}} type="submit">Ekle</button>
  </div>

  <label style={{marginTop: 20}}>{sonuc}</label>
</form>

    </div>
            {/* /.card */}
          </div>
          {/* /.col */}
        </div>
        {/* /.row */}
      {/* /.container-fluid */}
  </section>
  </div>
  <Footer/>
              </>
                    )
}

export default KullaniciEkle