import { render } from '@testing-library/react';
import React from 'react'
import { Component } from "react"
import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

const veri=[
    {
        id:0,
        firmaadi:"-",
        kadi:"-",
    },
];


function Giris() {
    let history = useHistory();

    const adminUser = {
        adminname: "ogzk",
        adminpassword: "ogzk34253606"
    }
    const [user,setUser] = useState({adminname:"",adminpassword:""});
    const [sonuc,setSonuc] = useState("");
    const [admin,setAdmin] = useState("");
    const [adminname,setAdminName] = useState("");
    const [adminpassword,setAdminPassword] = useState("");
    const handleAdminName = (e) => { setAdminName(e.target.value); }
    const handleAdminPassword = (e) => { setAdminPassword(e.target.value); }

    var config = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const obj = {
        adminname: adminname,
        adminpassword: adminpassword
    };


        const login =e=>{
            e.preventDefault();
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admncontrol.php",obj,config).then(response=>{
            if("hata"!=response.data)
            {
                setSonuc("Giriş yapılıyor..");
                setAdmin(response.data);
                let value1 = Math.floor(Math.random() * 1001);
                history.push('/Anasayfa/'+response.data[0].id+"/"+value1);
            }
            else
            {
                setSonuc("Kullanıcı adı veya şifre hatalı");
            }
          }).catch(error =>{
            setSonuc("Hata");
          });
    }

    useEffect(() => {
        const scriptTag = document.createElement('script');
        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    let {path,url} = useRouteMatch();
    return(
        <body className="hold-transition register-page" style={{background: '#343a40'}}>
<div className="register-box" style={{marginTop: '-100px', marginLeft: 'auto', marginRight: 'auto'}}>
  <div className="register-logo">
    <img src="dist/img/hanconlogo.png" width='220px' height='90px'/>
  </div>
  <div className="card" style={{marginTop: 10, border: '#e9ecef', width: '90%', marginLeft: 'auto', marginRight: 'auto'}}>
    <div className="card-body register-card-body" style={{background: '#e9ecef'}}>
      <p className="login-box-msg pull-left">Yönetim Paneli</p>
      <form onSubmit={login}>
      <div className="input-group mb-3">
        <input type="text" className="form-control" placeholder="Kullanıcı Adı" name="KULLANICIADI" onChange={handleAdminName}/>
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-user" />
          </div>
        </div>
      </div>
      <div className="input-group mb-3" style={{marginTop: 20}}>
        <input type="password" className="form-control" placeholder="Şifre" name="PAROLA" onChange={handleAdminPassword} />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-lock" />
          </div>
        </div>
      </div>
      <div className="input-group mb-3" style={{marginTop: 30}}>
        <button type="submit" className="btn btn-primary btn-block" style={{background: '#37548d', border: 'none'}}>Giriş Yap</button>
      </div>
      <div className="center" style={{marginTop: 10, textAlign: 'center'}}><h6><b>{sonuc}</b></h6></div>
      </form>
    </div>
    {/* /.form-box */}
  </div>{/* /.card */}
</div>
</body>
                    )
}

export default Giris