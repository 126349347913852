import './App.css';
import React, { useState } from 'react'
import logo from "./images/logo.png"
import Hakkimizda from './components/Hakkimizda'
import Urunler from './components/Urunler'
import Iletisim from './components/Oletisim'

import Giris from './components/Giris'
import Anasayfa from './components/Anasayfa'
import KullaniciEkle from './components/KullaniciEkle'
import Onayli from './components/Onayli'
import OnayBekleyen from './components/OnayBekleyen'
import Kapali from './components/Kapali'
import Proje from './components/Proje'
import ProjeDetay from './components/ProjeDetay'
import KayitGetir from './components/KayitGetir'
import KoordinatGuncelle from './components/KoordinatGuncelle'
import ProjeGuncelle from './components/ProjeGuncelle'

import Menu from './Menu'

import {Link,Switch,Route, useParams} from 'react-router-dom'

function App() {

  return (
    <div className="App">
    <div class="wrapper">
      <Switch>
      <Route path="/" exact><Giris/></Route>
      <Route path="/anasayfa/:id/:token"><Anasayfa/></Route>
      <Route path="/kullaniciekle"><KullaniciEkle/></Route>
      <Route path="/onayli/"><Onayli/></Route>
      <Route path="/onaybekleyen"><OnayBekleyen/></Route>
      <Route path="/kapali"><Kapali/></Route>
      <Route path="/kayitgetir/:id"><KayitGetir/></Route>
      <Route path="/proje/:id"><Proje/></Route>
      <Route path="/projedetay/:id/:proj_name"><ProjeDetay/></Route>
      <Route path="/koordinatguncelle/:id/:koordinatid"><KoordinatGuncelle/></Route>
      <Route path="/projeguncelle/:id/:projeid"><ProjeGuncelle/></Route>
      <Route path="/menu/"><Menu/></Route>
    </Switch>

    </div>
</div>
  );
}

function Child()
{
  let {id} = useParams();
  return(
    <div>
      <h3>ID:{id}</h3>
    </div>
  );
}
export default App;
