import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

const veri=[
    {
        id:0,
        firmaadi:"1",
        kadi:"2",
    }
];



function KayitGetir() {
    let history = useHistory();
    let {path,url} = useRouteMatch();

    const {id} = useParams();


    const [idd,setId] = useState("");
    const [userskayitgetir,setUsersKayitGetir] = useState([]);
    const [projectcount,setProjectCount] = useState([]);
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");
    const [sonuc,setSonuc] = useState("");

    const [kullaniciid,setKullaniciId] = useState("");
    const [firmaadi,setFirmaAdi] = useState("");
    const [kullaniciadi,setKullaniciAdi] = useState("");
    const [parola,setParola] = useState("");
    const [telefon,setTelefon] = useState("");
    const [email,setEmail] = useState("");
    const [lisanssuresi,setLisansSuresi] = useState("");
    const [profilresmi,setProfilResmi] = useState("");
    const [durum,setDurum] = useState("");

    const handleKullaniciId = (e) => { setKullaniciId(e.target.value); }
    const handleFirmaAdi = (e) => { setFirmaAdi(e.target.value); }
    const handleKullaniciAdi = (e) =>{ setKullaniciAdi(e.target.value); }
    const handleParola = (e) =>{ setParola(e.target.value); }
    const handleTelefon = (e) =>{ setTelefon(e.target.value); }
    const handleEmail = (e) =>{ setEmail(e.target.value); }
    const handleLisansSuresi = (e) =>{ setLisansSuresi(e.target.value); }
    const handleProfilResmi = (e) =>{ setProfilResmi(e.target.value); }
    const handleDurum = (e) =>{ setDurum(e.target.value); }

    const handleId = (e) => { setId(e.target.value); }

    const obj = {
        id: id,
    };

    const obj2 = {
      kullaniciid:kullaniciid,
      firmaadi:firmaadi,
      kullaniciadi:kullaniciadi,
      parola:parola,
      telefon:telefon,
      email:email,
      lisanssuresi:lisanssuresi,
      profilresmi:profilresmi,
      durum:durum
  };

  const kayitguncelle = (e)=>{
    e.preventDefault();
    axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnkayitguncelle.php",obj2,config).then(response=>{
            if("guncellendi" == response.data)
        {
            setSonuc("Kayıt güncellendi..!");
        }
    }).catch(error=>{
        setSonuc("Kayıt güncellenemiyor. Veritabanı hatası");
    });
}

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const kayitgetir = (e)=>{  
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnkayitgetir.php",obj,config).then(response=>{
            setUsersKayitGetir(response.data);
        }).catch(error=>{
            console.log("hata oluştu");
        });
    }

    const projectcountgetir = (e)=>{  
      axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnprojectcount.php",obj,config).then(response=>{
        setProjectCount(response.data);
      }).catch(error=>{
          console.log("hata oluştu");
      });
  }

  const getUsersLogged= () =>{
    axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
      if("1" == response.data[0].logged)
      {
        setLogged("1");
      }
      else
      {
        setLogged("0");
      }
      }).catch(error =>{
        console.log("hata oluştu");
      });
}

    useEffect(() => {
        getUsersLogged();
        projectcountgetir();
        kayitgetir();
        const scriptTag = document.createElement('script');
        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    if("0" == logged)
    {
      history.push('/');
    }

    return(
    userskayitgetir.map((userlinker)=>{
    return(

      <>
      <Header/>
      <Menu/>
      <Aside/>

        <div className="content-wrapper p-3">
        <section class="content">
              <div class="container-fluid">
        <div class="card card-default">   {/* Content Wrapper. Contains page content */}
          <div class="card-header">
                <h3 class="card-title">Güncelle</h3>     
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                </div>
            </div>
                    {/* /.card */}
                    <div class="card-body">
         <form action="/proje" method="post" encType="multipart/form-data">
         <label>Kullanıcı ID:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control" placeholder={userlinker.id} onChange={handleKullaniciId}/>
          </div>
         <label>Firma Adı:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control"  name="FIRMAADI" placeholder={userlinker.companyname} onChange={handleFirmaAdi}/>
          </div>
          <label>Kullanıcı Adı:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
            <input type="text" className="form-control" name="KULLANICIADI" placeholder={userlinker.name} onChange={handleKullaniciAdi} />
          </div>
          <label>Parola:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  name="PAROLA" placeholder={userlinker.password} onChange={handleParola} />
          </div>
          <label>Telefon:</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text"><i className="fas fa-phone" /></span>
            </div>
            <input type="text" name="TELEFON" className="form-control" placeholder={userlinker.phone} onChange={handleTelefon} />
          </div>
          {/* /.input group */}
          <label>E-mail:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-envelope" />
              </div>
            </div>
            <input type="email" className="form-control" name="EMAIL" placeholder={userlinker.email} onChange={handleEmail} />
          </div>
          <label>Lisans Süresi:</label>
          {/* Date */}
          <div className="input-group date" id="reservationdate" data-target-input="nearest">
            <input type="text" className="form-control datetimepicker-input" data-target="#reservationdate" name="LISANSSURESI" onChange={handleLisansSuresi} placeholder={userlinker.licencedate} lang="tr" />
            <div className="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
              <div className="input-group-text"><i className="fa fa-calendar" /></div>
            </div>
          </div>
          <label>Profil Resmi:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-image" />
              </div>
              <img src={userlinker.photo} width="100px" height="70px"/>
            </div>
            <input type="file" className="form-control" placeholder="Profil Resmi" id="LOGO" name="LOGO" onChange={handleProfilResmi} />
          </div>
          <label>Durum:</label>
          <div className="input-group">
                    <select className= "input-group-append col-md-12 p-2 border border-none rounded" name="DURUM"  onChange={handleDurum} data-placeholder="Lisans Seç">
                        <option value ="1">Onaylı</option>
                        <option value="0">Onay Bekleyen</option>
                        <option value="2">Kapalı</option>
                    </select>
          </div>
          <label>Toplam Proje Sayısı:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-archive" />
              </div>
            </div>
            <input type="text" className="form-control" name="TOPLAMPROJESAYISI" placeholder={projectcount} />
            <div>
                    <Link to = {`/proje/${id}`} className="btn btn-warning">Gör</Link>
            </div>
          </div>
          <div>
            <button className="btn btn-warning" style={{marginTop: 20}} onClick={kayitguncelle}>Güncelle</button>
          </div>
          <label style={{marginTop: 20}}>{sonuc}</label>
        </form>
        
            </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              {/* /.container-fluid */}
          </section>
          </div>
          <Footer/>
              </>
                    )
    })
    )
}

export default KayitGetir