import React, { Component } from "react"

import {Link,Switch,Route, useParams, useHistory} from 'react-router-dom'

export default class Menu extends Component{

    render(){
        return (
<div><aside className="main-sidebar sidebar-dark-primary elevation-4">
    {/* Brand Logo */}
    <Link to="/onayli"><img src="/dist/img/hanconlogo.png" alt="AdminLTE Logo" style={{width:'100%', height:'70px', marginTop:'20px'}} /></Link><br/>
     <a className="brand-link"> <span className="brand-text font-weight-light" style={{textAlign:'center', fontSize:'18px'}}>Hancon Konumlandırma <br/>Sistemleri<br/> Yönetim Paneli</span>
    </a>
    {/* Sidebar */}
    <div className="sidebar">
      {/* Sidebar user panel (optional) */}
      {/* Sidebar Menu */}
      <nav className="mt-2">
        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          {/* Add icons to the links using the .nav-icon class
                 with font-awesome or any other icon font library */}
          <li className="nav-item has-treeview menu-open">
            <a href="#" className="nav-link active">
              <i className="nav-icon fas fa-tachometer-alt" />
              <p>
                Yönetim
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
            <li className="nav-item">
              <Link  to="/kullaniciekle/" className="nav-link"><i className="far fa-circle nav-icon" />Kullanıcı Ekle</Link>
              </li>
              <li className="nav-item">
              <Link  to="/onayli/" className="nav-link"><i className="far fa-circle nav-icon" />Onaylı</Link>
              </li>
              <li className="nav-item">
              <Link  to="/onaybekleyen" className="nav-link"><i className="far fa-circle nav-icon" />Onay Bekleyen</Link>
              </li>
              <li className="nav-item">
              <Link  to="/kapali" className="nav-link"><i className="far fa-circle nav-icon" />Kapalı</Link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      {/* /.sidebar-menu */}
    </div>
    {/* /.sidebar */}
  </aside></div>
        )
  }
}