import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';


const veri=[
    {
        id:0,
        firmaadi:"1",
        kadi:"2",
    }
];

function ProjeGuncelle() {
    let history = useHistory();
    let {path,url} = useRouteMatch();

    const {id} = useParams();
    const {projeid} = useParams();

    const [idd,setId] = useState("");
    const [projects,setProjeGetir] = useState([]);
    const [sonuc,setSonuc] = useState("");
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");

    const handleId = (e) => { setId(e.target.value); }

    const [projeadi,setProjeAdi] = useState("");
    const [yazar,setYazar] = useState("");
    const [yorum,setYorum] = useState("");
    const [tarih,setDate] = useState("");
    const [zaman,setTime] = useState("");

    const handleProjeAdi = (e) => { setProjeAdi(e.target.value); }
    const handleYazar = (e) => { setYazar(e.target.value); }
    const handleYorum = (e) =>{ setYorum(e.target.value); }
    const handleDate = (e) =>{ setDate(e.target.value); }
    const handleTime = (e) =>{ setTime(e.target.value); }

    const obj = {
        id: id,
        projeid:projeid
    };

    const obj2 = {
        projeid:projeid,
        projeadi:projeadi,
        yazar:yazar,
        yorum:yorum,
        tarih:tarih,
        zaman:zaman
    };

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const projegetir = (e)=>{
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnprojedetail.php",obj,config).then(response=>{
          setProjeGetir(response.data);
        }).catch(error=>{
            console.log("hata oluştu");
        });
    }

    const projeguncelle = (e)=>{
        e.preventDefault();
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnprojeguncelle.php",obj2,config).then(response=>{
                if("guncellendi" == response.data)
            {
                setSonuc("Proje güncellendi..!");
            }
        }).catch(error=>{
            setSonuc("Proje güncellenemiyor. Veritabanı hatası");
        });
    }

    const getUsersLogged= () =>{
      axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
        if("1" == response.data[0].logged)
        {
          setLogged("1");
        }
        else
        {
          setLogged("0");
        }
        }).catch(error =>{
          console.log("hata oluştu");
        });
  }

    useEffect(() => {
        getUsersLogged();
        projegetir();
        const scriptTag = document.createElement('script');
        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    if("0" == logged)
    {
      history.push('/');
    }
    
    return(
        projects.map((userlinker)=>{
    return(
      <>
      <Header/>
      <Menu/>
      <Aside/>
        <div className="content-wrapper p-3">
        <section class="content">
              <div class="container-fluid">
        <div class="card card-default">   {/* Content Wrapper. Contains page content */}
          <div class="card-header">
                <h3 class="card-title">Güncelle</h3>     
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                </div>
            </div>
                    {/* /.card */}
                    <div class="card-body">
         <form onSubmit={projeguncelle}>
         <label>Proje ID:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control" placeholder={userlinker.id}/>
          </div>
         <label>Proje Adı:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.proj_name} onChange={handleProjeAdi} />
          </div>
          <label>Yazar:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
            <input type="text" className="form-control" placeholder={userlinker.proj_author} onChange={handleYazar}/>
          </div>
          <label>Yorum:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.proj_comment} onChange={handleYorum} />
          </div>
          <label>Tarih:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.proj_date} onChange={handleDate}/>
          </div>
          <label>Zaman:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.proj_time} onChange={handleTime}/>
          </div>

          <div>
            <button className="btn btn-warning" style={{marginTop: 20}}>Güncelle</button>
          </div>

          <label style={{marginTop: 20}}>{sonuc}</label>
        </form>
        
            </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              {/* /.container-fluid */}
          </section>
          </div>
          <Footer/>
              </>
                    )
    })
    )
}

export default ProjeGuncelle