import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

const veri=[
    {
        id:0,
        firmaadi:"1",
        kadi:"2",
    }
];



function KoordinatGuncelle() {
    let history = useHistory();
    let {path,url} = useRouteMatch();

    const {id} = useParams();
    const {koordinatid} = useParams();

    const [idd,setId] = useState("");
    const [koordinats,setKoordinatGetir] = useState([]);
    const [sonuc,setSonuc] = useState("");

    const handleId = (e) => { setId(e.target.value); }

    const [koordinatadi,setKoordinatAdi] = useState("");
    const [latitude,setLatitude] = useState("");
    const [longitude,setLongitude] = useState("");
    const [altitude,setAltitude] = useState("");

    const handleKoordinatAdi = (e) => { setKoordinatAdi(e.target.value); }
    const handleLatitude = (e) => { setLatitude(e.target.value); }
    const handleLongitude = (e) =>{ setLongitude(e.target.value); }
    const handleAltitude = (e) =>{ setAltitude(e.target.value); }

    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");

    const obj = {
        id: id,
        koordinatid:koordinatid
    };

    const obj2 = {
        koordinatid:koordinatid,
        koordinatadi:koordinatadi,
        latitude:latitude,
        longitude:longitude,
        altitude:altitude
    };

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const koordinatgetir = (e)=>{
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnpointdetail.php",obj,config).then(response=>{
            setKoordinatGetir(response.data);
        }).catch(error=>{
            console.log("hata oluştu");
        });
    }

    
    const koordinatguncelle = (e)=>{
        e.preventDefault();
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnpointguncelle.php",obj2,config).then(response=>{
                if("guncellendi" == response.data)
            {
                setSonuc("Koordinat güncellendi..!");
            }
        }).catch(error=>{
            setSonuc("Koordinat güncellenemiyor. Veritabanı hatası");
        });
    }

    const getUsersLogged= () =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
          if("1" == response.data[0].logged)
          {
            setLogged("1");
          }
          else
          {
            setLogged("0");
          }
          }).catch(error =>{
            console.log("hata oluştu");
          });
    }

    useEffect(() => {
        getUsersLogged();
        koordinatgetir();
        const scriptTag = document.createElement('script');
        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    if("0" == logged)
    {
      history.push('/');
    }
    
    return(
        koordinats.map((userlinker)=>{
    return(

        <>
        <Header/>
        <Menu/>
        <Aside/>

        <div className="content-wrapper p-3">
        <section class="content">
              <div class="container-fluid">
        <div class="card card-default">   {/* Content Wrapper. Contains page content */}
          <div class="card-header">
                <h3 class="card-title">Güncelle</h3>     
                <div class="card-tools">
                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                    <button type="button" class="btn btn-tool" data-card-widget="remove"><i class="fas fa-times"></i></button>
                </div>
            </div>
                    {/* /.card */}
                    <div class="card-body">
         <form onSubmit={koordinatguncelle}>
         <label>Koordinat ID:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control" placeholder={userlinker.id}/>
          </div>
         <label>Koordinat Adı:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-home" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.point_name} onChange={handleKoordinatAdi} />
          </div>
          <label>Enlem:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-user" />
              </div>
            </div>
            <input type="text" className="form-control" placeholder={userlinker.point_latitude} onChange={handleLatitude}/>
          </div>
          <label>Boylam:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.point_longitude} onChange={handleLongitude} />
          </div>
          <label>Rakım:</label>
          <div className="input-group">
            <div className="input-group-append">
              <div className="input-group-text">
                <span className="fas fa-lock" />
              </div>
            </div>
            <input type="text" className="form-control"  placeholder={userlinker.point_altitude} onChange={handleAltitude}/>
          </div>

          <div>
            <button className="btn btn-warning" style={{marginTop: 20}}>Güncelle</button>
          </div>

          <label style={{marginTop: 20}}>{sonuc}</label>
        </form>
        
            </div>
                    {/* /.card */}
                  </div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              {/* /.container-fluid */}
          </section>
          </div>

          <Footer/>
              </>
                    )
    })
    )
}

export default KoordinatGuncelle