import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

const veri=[
    {
        id:0,
        projeadi:"firma0",
        yazar:"yazar0",
        yorum:"yorum0",
        tarih:"tarih0",
        zaman:"zaman0",
    }
];

function Proje() {
    let history = useHistory();
    let {path,url} = useRouteMatch();

    const {id} = useParams();
    
    const [idd,setId] = useState("");
    const [projectsgetir,setProjectsGetir] = useState([]);
    const [sonuc,setSonuc] = useState("");
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");
    
    const obj = {
        id: id,
    };

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const projegetir = (e)=>{  
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnprojegetir.php",obj,config).then(response=>{
            setProjectsGetir(response.data);
        }).catch(error=>{
            console.log("hata oluştu");
        });
    }

    const sil= (e) =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnsilproject.php?id="+ e.target.value,config).then(response=>{
          setSonuc(""+ e.target.value +" id'li Proje Silindi..!");
        }).catch(error =>{
          setSonuc("Proje silme hatası..!");
          });
    }

    const getUsersLogged= () =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
          if("1" == response.data[0].logged)
          {
            setLogged("1");
          }
          else
          {
            setLogged("0");
          }
          }).catch(error =>{
            console.log("hata oluştu");
          });
    }

    useEffect(() => {
        getUsersLogged();
        projegetir();
        const scriptTag = document.createElement('script');

        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);
        
    if("0" == logged)
    {
      history.push('/');
    }
    return(

        <>
        <Header/>
        <Menu/>
        <Aside/>

            <div>   {/* Content Wrapper. Contains page content */}
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-6">
                        <h4>Hancon Kullanıcı Bilgileri</h4>
                      </div>
                      <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                          <li className="breadcrumb-item"><a href="#">Home</a></li>
                          <li className="breadcrumb-item active">DataTables</li>
                        </ol>
                      </div>
                    </div>
                  </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        {/* /.card */}
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Proje Listesi</h3>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Proje ID</th>
                                  <th>Proje Adı</th>
                                  <th>Yazar</th>
                                  <th>Yorum</th>
                                  <th>Tarih</th>
                                  <th>Zaman</th>
                                  <th>Sil</th>
                                  <th>Gör</th>
                                  <th>Güncelle</th>
                                </tr>
                              </thead>
                              <tbody>
                              {      
                                      veri.map((linker)=>{
                                      return(
                                      <>
                                      <tr hidden>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      </tr></>
                                           )
                                          })
                                }
                                  {
                                projectsgetir.map((linkler)=>{
                                    return(
                                <><tr>
                                            <td>{linkler.id}</td>
                                            <td>{linkler.proj_name}</td>
                                            <td>{linkler.proj_author}</td>
                                            <td>{linkler.proj_comment}</td>
                                            <td>{linkler.proj_date}</td>
                                            <td>{linkler.proj_time}</td>
                                            <td><button value={linkler.id} className="btn btn-danger" onClick={sil}>Sil</button></td>
                                            <td><Link to={`/projedetay/${linkler.user_id}/${linkler.proj_name}`} className="btn btn-warning">Gör</Link></td>
                                            <td><Link to={`/projeguncelle/${linkler.user_id}/${linkler.id}`} className="btn btn-success">Güncelle</Link></td>
                                        </tr></>
                                             )
                                            })
                                }
                              </tbody>
                            </table>
                          </div>
                          <label style={{marginTop: 20,marginLeft:20}}>{sonuc}</label>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                      </div>
                      {/* /.col */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </section>
                {/* /.content */}
              </div>  </div>

              <Footer/>
              </>
                    )
}

export default Proje