import React, { Component } from "react"

export default class Aside extends Component{

    render(){

        return (
<div>
  {/* Main Sidebar Container */}


<aside className="control-sidebar control-sidebar-dark">
</aside>

  </div>

        )
    }
}