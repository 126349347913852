import { render } from '@testing-library/react';
import React from 'react'
import { Component } from "react"
import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

const veri=[
    {
        id:0,
        firmaadi:"-",
        kadi:"-",
    },
];


function Onayli() {

    let history = useHistory();

    const [usersonayli,setUsersOnayli] = useState([]);
    const [sonuc,setSonuc] = useState("");
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");
    var config = {
        headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const getUsersOnayli= () =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnreadonayli.php").then(response=>{
            setUsersOnayli(response.data);
          }).catch(error =>{
            console.log("hata oluştu");
          });
    }

    const sil= (e) =>{
      axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnsilonayli.php?id="+ e.target.value).then(response=>{
        setSonuc(""+ e.target.value +" id'li Kullanıcı Silindi..!");
      }).catch(error =>{
        setSonuc("Kullanıcı silme hatası..!");
        });
  }

  const getUsersLogged= () =>{
    axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
      if("1" == response.data[0].logged)
      {
        setLogged("1");
      }
      else
      {
        setLogged("0");
      }
      }).catch(error =>{
        console.log("hata oluştu");
      });
}

var config = {
  headers: {'Content-Type': 'application/x-www-form-urlencoded'}
};

const obj = {
  id:1,
};

const cikisyap = (e)=>{
  e.preventDefault();
  axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedguncelle.php",obj,config).then(response=>{
          if("guncellendi" == response.data)
      {
          history.push("/");
      }
  }).catch(error=>{
      setSonuc("Çıkış yapılamıyor. Veritabanı hatası");
  });
}

    useEffect(() => {
        getUsersLogged();
        getUsersOnayli();
        const scriptTag = document.createElement('script');
        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);

    let {path,url} = useRouteMatch();

    if("0" == logged)
    {
      history.push('/');
    }
    return(
      <>
<Header/>
<Menu/>
<Aside/>
            <div>   {/* Content Wrapper. Contains page content */}
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-3 col-md-3">
                        <h5>Hancon Kullanıcı Bilgileri</h5>
                      </div>
                      <div className="col-sm-6 col-md-6">
                      </div>
                      <div className="col-sm-3 col-md-3">
                        <Link onClick={cikisyap}><h6>Çıkış Yap</h6></Link>
                      </div>
                    </div>
                  </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12">
                        {/* /.card */}
                        <div className="card">
                          <div className="card-header">
                            <h3 className="card-title">Onaylı</h3>
                          </div>
                          {/* /.card-header */}
                          <div className="card-body">
                            <table id="example1" className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Kullanıcı ID</th>
                                  <th>Firma Adı</th>
                                  <th>Kullanıcı Adı</th>
                                  <th>Sil</th>
                                  <th>Güncelle</th>
                                </tr>
                              </thead>
                              <tbody>
                              {      
                                      veri.map((linker)=>{
                                      return(
                                      <>
                                      <tr hidden>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      </tr></>
                                           )
                                          })
                                }
                                  {
                                      
                                        usersonayli.map((userlinker)=>{
                                        return(
                                        <>
                                        <tr>
                                        <td>{userlinker.id}</td>
                                        <td>{userlinker.companyname}</td>
                                        <td>{userlinker.name}</td>
                                        <td><button value={userlinker.id} className="btn btn-danger" onClick={sil}>Sil</button></td>
                                        <td><Link to={`/kayitgetir/${userlinker.id}`} className="btn btn-success">Güncelle</Link></td>
                                        </tr></>
                                             )
                                            })
                                }
                              </tbody>
                            </table>
                          </div>
                          <label style={{marginTop: 20,marginLeft:20}}>{sonuc}</label>
                          {/* /.card-body */}
                        </div>
                        {/* /.card */}
                      </div>
                      {/* /.col */}
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </section>
                {/* /.content */}
              </div>  
       
              </div>
              <Footer/>
              </>
                    )
}

export default Onayli