import React from 'react'

import {Link,Switch,Route, useParams, useRouteMatch, useHistory} from 'react-router-dom'
import { useEffect,useState } from 'react';
import axios  from 'axios';

import Header from '../Header';
import Menu from '../Menu';
import Footer from '../Footer';
import Aside from '../Aside';

const veri=[
    {
        id:0,
        koordinatadi:"k0",
        enlem:"enlem0",
        boylam:"boylam0",
        rakim:"rakim0",
    }
];

function Proje() {
    let history = useHistory();
    let {path,url} = useRouteMatch();

    const {id} = useParams();
    const {proj_name} = useParams();

    const [idd,setId] = useState("");
    const [projepointsgetir,setProjePointsGetir] = useState([]);
    const [sonuc,setSonuc] = useState("");
    const [userslogged,setUsersLogged] = useState([]);
    const [logged,setLogged] = useState("");

    const obj = {
        id: id,
        proj_name:proj_name
    };

    var config = {
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
    };
    
    const pointsgetir = (e)=>{  
        axios.post("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnpointsgetir.php",obj,config).then(response=>{
            setProjePointsGetir(response.data);
        }).catch(error=>{
            console.log("hata oluştu");
        });
    }

    const sil= (e) =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnsilpoint.php?id="+ e.target.value,config).then(response=>{
          setSonuc(""+ e.target.value +" id'li Koordinat Silindi..!");
        }).catch(error =>{
          setSonuc("Koordinat silme hatası..!");
          });
    }

    const getUsersLogged= () =>{
        axios.get("https://hanconmobile.oguzkagansavunma.com/android_register_login/admnloggedcontrol.php").then(response=>{
          if("1" == response.data[0].logged)
          {
            setLogged("1");
          }
          else
          {
            setLogged("0");
          }
          }).catch(error =>{
            console.log("hata oluştu");
          });
    }

    useEffect(() => {
        getUsersLogged();
        pointsgetir();
        const scriptTag = document.createElement('script');

        scriptTag.src = "/js/content.js";
        scriptTag.async = true;

        document.body.appendChild(scriptTag);
        return () => {
            document.body.removeChild(scriptTag);
        }
    }, []);
    
    if("0" == logged)
    {
      history.push('/');
    }

    return(
        <>
        <Header/>
        <Menu/>
        <Aside/>

        <div>   {/* Content Wrapper. Contains page content */}
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h4>Proje Detayı</h4>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                    <li className="breadcrumb-item active">DataTables</li>
                  </ol>
                </div>
              </div>
            </div>{/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  {/* /.card */}
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Koordinat Listesi</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">
                      <table id="example1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Koordinat ID</th>
                            <th>Koordinat Adı</th>
                            <th>Enlem</th>
                            <th>Boylam</th>
                            <th>Rakım</th>
                            <th>Sil</th>
                            <th>Güncelle</th>
                          </tr>
                        </thead>
                        <tbody>
                        {      
                                      veri.map((linker)=>{
                                      return(
                                      <>
                                      <tr hidden>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      </tr></>
                                           )
                                          })
                                }
                                  {
                                projepointsgetir.map((linkler)=>{
                                    return(
                                <><tr>
                                            <td>{linkler.id}</td>
                                            <td>{linkler.point_name}</td>
                                            <td>{linkler.point_latitude}</td>
                                            <td>{linkler.point_longitude}</td>
                                            <td>{linkler.point_altitude}</td>
                                            <td><button value={linkler.id} className="btn btn-danger" onClick={sil}>Sil</button></td>
                                            <td><Link to={`/koordinatguncelle/${linkler.user_id}/${linkler.id}`} className="btn btn-success">Güncelle</Link></td>
                                        </tr></>
                                             )
                                            })
                                }
                        </tbody>
                      </table>
                    </div>
                    <label style={{marginTop: 20,marginLeft:20}}>{sonuc}</label>
                    {/* /.card-body */}
                  </div>
                  {/* /.card */}
                </div>
                {/* /.col */}
              </div>
              {/* /.row */}
            </div>
            {/* /.container-fluid */}
          </section>
          {/* /.content */}
        </div>  </div>

        <Footer/>
              </>
                    )
}

export default Proje